<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="用户ID" prop="userId">-->
<!--                <a-input v-model="queryParam.userId" placeholder="请输入用户ID" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="6" :sm="24">
              <a-form-item label="宠物名字" prop="petName">
                <a-input v-model="queryParam.petName" placeholder="请输入宠物名字" allow-clear/>
              </a-form-item>
            </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="阶段" prop="stage">
                  <a-input v-model="queryParam.stage" placeholder="请输入阶段" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="宠物等级" prop="grade">
                  <a-input v-model="queryParam.grade" placeholder="请输入宠物等级" allow-clear/>
                </a-form-item>
              </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:pet:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:pet:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:pet:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:pet:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form  ref="createForm"     @ok="getList"  />
      <!-- 数据展示   :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
           <span slot="petAvatar" slot-scope="text, record">
            <img style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="record.petAvatar"
                 v-for="(guildLogo,imgIndex) in record.petAvatar.split(',')"
                 :src="guildLogo"
                 preview="图片" />
        </span>

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['biz:pet:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:pet:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['biz:pet:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['biz:pet:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pagePet,listPet, delPet } from '@/api/biz/pet'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Pet',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        petName: null,
        stage: null,
        grade: null,
        catFoodBalance: null,
        catMilkEggBalance: null,
        driedFishBalance: null,
        clothingId: null,
        catBoxId: null,
        changeNameCard: null,
        useChangeName: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '主键',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '宠物头像',
          dataIndex: 'petAvatar',
          ellipsis: true,
          align: 'center', scopedSlots: {customRender: "petAvatar"}
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center',width:'120px'
        },
        {
          title: '宠物名字',
          dataIndex: 'petName',
          ellipsis: true,
          align: 'center',width:'120px'
        },
        {
          title: '阶段',
          dataIndex: 'stage',
          ellipsis: true,
          align: 'center' ,width:'90px'
        },
        {
          title: '宠物等级',
          dataIndex: 'grade',
          ellipsis: true,
          align: 'center',width:'90px'
        },
        {
          title: '经验值',
          dataIndex: 'experienceValue',
          ellipsis: true,
          align: 'center',width:'90px'
        },
        // {
        //   title: '宠物信息ID',
        //   dataIndex: 'petInfoId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '宠物图片',
        //   dataIndex: 'petImage',
        //   ellipsis: true,
        //   align: 'center'
        // },

        {
          title: '猫粮数',
          dataIndex: 'catFoodBalance',
          ellipsis: true,
          align: 'center',width:'90px'
        },
        {
          title: '猫奶蛋数',
          dataIndex: 'catMilkEggBalance',
          ellipsis: true,
          align: 'center',width:'90px'
        },
        {
          title: '小鱼干数',
          dataIndex: 'driedFishBalance',
          ellipsis: true,
          align: 'center',width:'90px'
        },
        // {
        //   title: '服装ID',
        //   dataIndex: 'clothingId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '猫窝ID',
        //   dataIndex: 'catBoxId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '改名卡数',
          dataIndex: 'changeNameCard',
          ellipsis: true,
          align: 'center',width:'90px'
        },
        {
          title: '免费改名卡',
          dataIndex: 'useChangeName',
          ellipsis: true,
          align: 'center',width:'100px',
          customRender: function (value) {
            if (value==1){
              return "已使用"
            }else{
              return "未使用"
            }
          }
        },

        {
          title: '领取时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户宠物列表 */
    getList () {
      this.loading = true
     pagePet(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        petName: undefined,
        stage: undefined,
        grade: undefined,
        catFoodBalance: undefined,
        catMilkEggBalance: undefined,
        driedFishBalance: undefined,
        clothingId: undefined,
        catBoxId: undefined,
        changeNameCard: undefined,
        useChangeName: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delPet(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/pet/export', {
            ...that.queryParam
          }, `用户宠物_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
